<template>
    <div>
        <h3>{{ $t('ordonnance.multi_ordonnances') }}</h3>

        <div v-if="horses.length > 0" class="mb-2">
            <router-link v-if="horses.length === 1" :to="{ name: 'horseFiche', params: { horse_id: horses[0].horse_id }}">
                <font-awesome-icon :icon="['fas', 'horse-head']" /> {{ actualHorseName }}
            </router-link>
            <router-link v-else id="horse_names" :to="{ name: 'horseListe'}">
                <font-awesome-icon :icon="['fas', 'horse-head']" /> {{ actualHorseName }}
            </router-link>
        </div>

		<label>{{ $t('ordonnance.donner_un_nom') }}* :</label>
        <div class="form-group">
            <input type="text" class="form-control" v-model="document_name" :placeholder="$t('ordonnance.donner_un_nom')" required>
        </div>
		
        <label>{{ $t('ordonnance.documents_dates') }}* :</label>
        <div class="form-group">
            <e-datepicker v-model="document_date_multiple" required />
        </div>
        <label>{{ $t('fichier.fichiers') }}* :</label>
        <b-form-file
            v-model="document_file_multiple"
            :multiple="true"
            :accept="ordonnance_type_file_accepted"
            :state="Boolean(document_file_multiple) && isValidSizeFileMultiple"
            :placeholder="$t('ordonnance.choisir_documents')"
            :drop-placeholder="$t('fichier.drop_files')+'...'"
            ref="fileFormMultiple"
        ></b-form-file>
        <div v-show="document_file_multiple && isValidSizeFileMultiple" class="mt-3">
            {{ $t('fichier.selected_files') }} :
            <span v-for="document in document_file_multiple" :key="document.name">
                {{ document ? document.name : '' }}
            </span>
        </div>
        <span v-if="document_file_multiple && !isValidSizeFileMultiple">
            <br>
            <ErrorFileSize messageI18n="fichier.fichier_trop_lourd" />
        </span>

        <div class="text-center mt-2">
            <button class="btn btn-primary" @click="valid_form_multiple"> 
                <font-awesome-icon :icon="['fal', 'check']" />
                {{ $t('global.valider') }}
            </button>
        </div>
    </div>
</template>
<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
    import Documents from "@/mixins/Documents.js"
    import Horse from "@/mixins/Horse.js"
    import Common from '@/assets/js/common.js'


    export default {
        name: 'Ordonnance',
        mixins: [Documents, Shutter, Horse],
        props: {
            horses_ids: { type: Array, default: () => [] },
            actes_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => [] },
            next: { type: Array, default: () => [] }
        },
        data(){
            return {
                horses: [],
                document_date: null,
                document_limit_size: '10', // en Mo
                actual_date: new Date(),
                document_file_multiple: null,
                document_date_multiple: new Date(),
                document_name: '',
            }
        },
        mounted(){
            this.init_component()
        },
        methods: {
            async init_component(){
                this.document_date = this.date_acte
                this.document_date_multiple = this.date_acte
                this.horses = await this.getHorsesByIds(this.horses_ids)
            },
            async valid_form() {
				await this.addHorseDocument(this.horses_ids, this.document_name, this.document_date, 1, this.document_file_multiple[0], this.acte_ids)
                this.closeShutter()
			},
            async valid_form_multiple() {
                if(!this.isValidSizeFileMultiple) return
                let i = 1

                await Common.asyncForEach(this.document_file_multiple, async (file) => {
                    await this.addHorseDocument(this.horses_ids, this.document_name + '_' + i, this.document_date, 1, file, this.acte_ids)
                    i++
                })

                this.closeShutter()
            },
			closeShutter() {
				if(this.next.length > 0) {
                    const triggered = this.next[0]
                    this.next.shift()
                    this.shutterPanel().close('ordonnance')
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
                } else { 
                    this.$router.push({ name: 'ordonnanceListe'})
                }
			}
		},
        computed: {
            isValidSizeFileMultiple() { 
                let isOk = true
                const limit = this.document_limit_size * 1000000 
                this.document_file_multiple.forEach(file => {
                    const file_size = file.size
                    if(file_size >= limit) {
                        isOk = false
                    }
                })
                return isOk
            },
            actualHorseName() {
				return this.horses
					.map(h => h.horse_nom)
					.join(', ')
            },
        },
        components: {
            ErrorFileSize: () => import('GroomyRoot/components/Alert/ErrorAlert')
        }
    }
</script>